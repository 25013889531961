import intlTelInput from "intl-tel-input/build/js/intlTelInputWithUtils.js"
import t from "./translator.js"
import "intl-tel-input/build/css/intlTelInput.css"

window.phoneInput = (telId, country, xModel) => {
	return {
		xModel: xModel, // Alpine.js model name to bind the input value to
		country: country,
		telId: telId,
		iti: null, // intlTelInput instance

		init() {
			let input = document.querySelector("input[data-telid=" + this.telId + "]")
			input.addEventListener("input", () => {
				this[this.xModel] = this.iti.getNumber()
			})

			this.iti = intlTelInput(input, {
				countryOrder: [this.country],
				initialCountry: this.country,
				localizedCountries: window.phoneInputTranslatedCountries,
				nationalMode: true,
				onlyCountries: window.phoneInputPermittedCountries,
				allowDropdown: true,
				showFlags: true,
			})

			// On form submission, quickly replace with e.164 format
			const parentForm = input.closest("form")
			if (parentForm) {
				parentForm.addEventListener("submit", () => {
					input.value = this.iti.getNumber()
				})
			} else {
				console.error("Phone input must be inside a form to work properly.")
			}

			// Validate
			input.addEventListener("keyup", () => {
				let error = input.closest(".xTextInputComponent").querySelector(".phoneInputError")

				const errorMap = [
					t("Invalid number"),
					t("Invalid country code"),
					t("Too short"),
					t("Too long"),
					t("Invalid number"),
				]

				if (input.value.trim() !== "") {
					if (this.iti.isValidNumber()) {
						error.textContent = ""
					} else {
						let errorCode = this.iti.getValidationError()
						error.textContent = errorMap[errorCode]
					}
				}
			})
		},
	}
}
