/**
 * UI translation
 * @param {string} uiString
 * @param {Object} tokens
 * @param {string} language
 * @returns {string}
 */
export default function t(uiString, tokens = {}, language = null) {
	uiString = uiString?.trim() || ""
	if (uiString.length === 0) {
		return ""
	}

	language = language || document.documentElement.lang.substring(0, 2) || "en"
	const debug = window.translateDebugEnabled ?? false

	if (language == "en") {
		return processTokens(uiString, true, tokens, debug, language)
	} else {
		let translation = getTranslation(uiString, language)

		if (translation === null && !doesSourceExist(uiString)) {
			if (process.env.NODE_ENV === "development") {
				throw new Error("Something was passed to t() that isn't a ui string!")
			} else if (process.env.NODE_ENV !== "test") {
				console.error("Something was passed to t() that isn't a ui string!", { uiString: uiString })
			}
		}

		return processTokens(translation, translation !== null, tokens, debug, language)
	}
}

function processTokens(result, translationExists, tokens, debug, language) {
	for (let [key, value] of Object.entries(tokens)) {
		result = result.replace(`{${key}}`, value)
	}

	if (debug) {
		return translationExists ? `(${language}:${result})` : `(${language}!${result})`
	} else {
		return result
	}
}

function getTranslation(uiString, language) {
	if (window.translatorData == undefined) {
		return null
	}
	if (window.translatorData[uiString] == undefined) {
		return null
	}
	if (window.translatorData[uiString][language] == undefined) {
		return null
	}
	return window.translatorData[uiString][language]
}

function doesSourceExist(uiString) {
	return window.translatorData != undefined && window.translatorData[uiString] != undefined
}
